<template>
  <div class="start-site-conf">
    <ml-tip :showHeader="false" content="用户起点配置" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 阿米巴 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
        />
        <ml-select
          prop="userId"
          placeholder="请选择人员名称"
          :options="userOptions"
          label="人员名称:"
          keyName="userId"
          labelName="userName"
          valueName="userId"
          :filterable="true"
          v-model="searchData.userId"
          :style="{ marginBottom: 0, marginRight: '40px' }"
          :disabled="disabledProject"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
      <div class="add-btn-box">
        <el-button-group>
          <el-button icon="el-icon-plus" type="primary" @click="addHandle">新增</el-button>
          <el-button type="primary" @click="jumpHandle">坐标拾取</el-button>
        </el-button-group>
      </div>
    </ml-tip>

    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(360)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="用户名称"
          prop="userName"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="站点位置(目的地)"
          prop="siteAddr"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="经度"
          prop="longitude"
          min-width="15%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="纬度"
          prop="latitude"
          min-width="15%"
        />
        <el-table-column align="center" label="操作" priop="createById" min-width="10%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
    <site-dialog
      v-if="showDialog"
      ref="siteRef"
      :editData="editData"
      :operType="operType"
      @close="closeHandle"
      @saveSuccess="saveSuccessHandle"
    ></site-dialog>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { tabberHeight, searchParams, getTabberData, clearMemoryPageFn } from '@/utils'
import siteDialog from './components/siteDialog.vue'
export default {
  components: {
    siteDialog
  },
  setup() {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const getMemoryPage = computed(() => getters.getMemoryPage)
    const token = computed(() => getters.getAccessToken)
    const searchDataRef = ref()
    const searchData = reactive({
      companyId: getMemoryPage.value.searchData.companyId || '',
      userId: getMemoryPage.value.searchData.userId || ''
    })
    const tabberData = reactive({
      data: [],
      total: 0,
      page: getMemoryPage.value.page || 1,
      size: getMemoryPage.value.size || 10
    })
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      clearMemoryPageFn(commit, searchData)
      tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
    }
    const searchFn = () => {
      tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
    }
    const unitOptions = ref([])
    watch([() => tabberData.page, () => tabberData.size], () => {
      getSiteLocations()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    // 获取列表
    const getSiteLocations = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      const { data } = await getTabberData(dispatch, 'fetchPointConfPage', params, searchDataParams)
      tabberData.data = data.records || []
      tabberData.total = data.total || 0
    }

    // 删除
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该用户起点吗？',
      id: ''
    })

    const clickDelete = (index, row) => {
      deleteUserData.id = row.userId
      deleteUserDialogRef.value.showDialog = true
    }

    // 确定删除
    const submitDeleteUser = () => {
      const { id } = deleteUserData
      dispatch('fetchDeletePointConf', { userId: id }).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getSiteLocations() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }
    /* 开始 新增/修改 **********************************/
    const showDialog = ref(false)
    const siteRef = ref(null)
    const editData = ref({})
    const operType = ref('')
    const addHandle = async () => {
      operType.value = 'add'
      showDialog.value = true
      editData.value = {}
      await nextTick()
      siteRef.value.dialogRef.showDialog = true
    }
    const closeHandle = () => {
      operType.value = ''
      showDialog.value = false
    }
    const saveSuccessHandle = () => {
      searchFn()
    }
    const clickEdit = async (index, row) => {
      operType.value = 'edit'
      showDialog.value = true
      editData.value = row
      await nextTick()
      siteRef.value.dialogRef.showDialog = true
    }

    const jumpHandle = () => {
      window.open('https://lbs.amap.com/tools/picker', '_blank')
    }

    const userOptions = ref([])
    // 获取用户列表
    const getUserListData = () => {
      let data = {
        userId: '',
        projectId: '',
        companyId: searchData.companyId,
        userName: ''
      }
      dispatch('fetchGetSelectUserByParam', data).then(data => {
        if (data && data.code === 200) {
          // 进页面时，初始
          userOptions.value = data.data || []
        }
      })
    }
    // 监听
    watch(
      () => searchData.companyId,
      () => {
        userOptions.value = []
        searchData.userId = ''
        getUserListData()
      }
    )

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      getSiteLocations()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      getSiteLocations,
      tabberData,
      tabberHeight,
      currentChange,
      sizeChange,
      unitOptions,
      isBusinessDepartment,
      getMemoryPage,
      token,
      clickDelete,
      submitDeleteUser,
      deleteUserDialogRef,
      deleteUserData,
      showDialog,
      addHandle,
      siteRef,
      editData,
      closeHandle,
      saveSuccessHandle,
      clickEdit,
      jumpHandle,
      userOptions,
      operType
    }
  }
}
</script>
<style lang="scss" scoped>
.start-site-conf {
  .add-btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.import-equipment-dialog-box {
  width: 100%;
  padding: 0 20px;
  .import-equipment-dialog {
    width: 100%;
    display: flex;
    // justify-content: start;
    align-items: center;
    span {
      margin-right: 20px;
    }
    &:first-child {
      margin-bottom: 20px;
    }
  }
}
</style>
