<template>
  <ml-dialog
    width="640px"
    ref="dialogRef"
    :title="title"
    @click-submit="saveHandle"
    @click-close="closeHandle"
  >
    <template #body>
      <ml-form
        :model="itemData"
        ref="formRef"
        :rules="formRules"
        labelWidth="100px"
        style="width: 100%"
      >
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="itemData.companyId"
          @change="selectUnit"
          :disabled="operTypePage === 'edit'"
        />
        <!-- 所属项目 -->
        <ml-select
          prop="userId"
          placeholder="请选择人员"
          :options="userOptions"
          label="用户:"
          keyName="userId"
          labelName="userName"
          valueName="userId"
          v-model="itemData.userId"
          :disabled="operTypePage === 'edit'"
        />
        <ml-input
          prop="siteAddr"
          label="起点地址:"
          placeholder="请输入起点地址"
          v-model.trim="itemData.siteAddr"
        />
        <ml-input
          prop="longitude"
          label="经度:"
          placeholder="请输入经度"
          v-model.trim="itemData.longitude"
        />
        <ml-input
          prop="latitude"
          label="纬度:"
          placeholder="请输入纬度"
          v-model.trim="itemData.latitude"
        />
      </ml-form>
    </template>
  </ml-dialog>
</template>

<script>
import { ref, reactive, onMounted, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { getTabberData } from '@/utils'
export default {
  props: {
    editData: {
      type: Object,
      default: () => ({})
    },
    operType: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { commit, getters, dispatch } = useStore()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const title = ref('新增站点')
    const operTypePage = ref(props.operType)
    const dialogRef = ref(null)
    const openDialog = () => {
      operTypePage.value = props.operType
      console.log(`operTypePage.value: ${operTypePage.value}`)
      dialogRef.value.open()
    }
    const closeHandle = () => {
      emit('close')
    }
    const itemData = reactive({
      id: '',
      companyId: '',
      userId: '',
      siteAddr: '',
      longitude: '',
      latitude: '',
      createTime: ''
    })
    watch(
      () => props.editData,
      async newvalue => {
        if (Object.keys(newvalue).length) {
          title.value = '编辑站点'
          itemData.id = newvalue.id
          itemData.companyId = newvalue.companyId
          itemData.userId = newvalue.userId
          itemData.siteAddr = newvalue.siteAddr
          itemData.longitude = newvalue.longitude
          itemData.latitude = newvalue.latitude
          itemData.createTime = newvalue.createTime
          await nextTick()
          getUserListData(itemData.companyId)
        }
      },
      { immediate: true, deep: true }
    )
    const formRules = reactive({
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      userId: [{ required: true, message: '请选择用户', trigger: 'blur' }],
      siteAddr: [{ required: true, message: '请输入站点目的地', trigger: 'blur' }],
      longitude: [
        { required: true, message: '请输入经度', trigger: 'blur' },
        {
          pattern:
            /^-?(180(\.0{6,8})?|1[0-7][0-9](\.\d{6,8})?|[1-9][0-9](\.\d{6,8})?|[1-9](\.\d{6,8})?)$/,
          message: '经度格式不正确, 必须精确到6位小数',
          trigger: 'blur'
        }
      ],
      latitude: [
        { required: true, message: '请输入纬度', trigger: 'blur' },
        {
          pattern: /^-?(90(\.0{6,8})?|[1-8][0-9](\.\d{6,8})?|[1-9](\.\d{6,8})?)$/,
          message: '纬度格式不正确，必须精确到6位小数',
          trigger: 'blur'
        }
      ]
    })

    // 单位名下拉列表
    const unitOptions = ref([])
    const selectUnit = () => {
      itemData.userId = ''
      userOptions.value = []
    }
    watch(
      () => itemData.companyId,
      newvalue => {
        getUserListData(newvalue)
      }
    )
    const userOptions = ref([])
    // 获取用户列表
    const getUserListData = companyId => {
      let data = {
        userId: '',
        projectId: '',
        companyId: companyId,
        userName: ''
      }
      dispatch('fetchGetSelectUserByParam', data).then(data => {
        if (data && data.code === 200) {
          // 进页面时，初始
          userOptions.value = data.data || []
        }
      })
    }
    const formRef = ref(null)
    const saveHandle = () => {
      formRef.value.CustomFormRef.validate().then(() => {
        dispatch('fetchInsertOrUpdatePointConf', itemData).then(data => {
          if (data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            emit('save-success')
            closeHandle()
          }
        })
      })
    }
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
    })

    return {
      title,
      openDialog,
      closeHandle,
      saveHandle,
      dialogRef,
      itemData,
      formRules,
      unitOptions,
      getUserListData,
      userOptions,
      selectUnit,
      formRef,
      operTypePage
    }
  }
}
</script>